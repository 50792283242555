import { ReduxService } from 'dashboard-services';

import { basicUploadActions, fileUploadActions } from 'actions/upload';

const emptyField = {
  name: "",
  value: "",
  canBeRemoved: true
}

export const uploadInitialState = {
  errors: [],
  groupName: undefined,
  fileName: undefined,
  fileType: undefined,
  sheetName: undefined,
  firstCell: undefined,
  lastCell: undefined,
  fields: [],
  jobIds: [],
  areStatusesHidden: false
}

export default (state = uploadInitialState, action) => {
  switch (action.type) {
    case basicUploadActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case basicUploadActions.SET_ERRORS:
      return ReduxService.updateObject(state, { errors: action.errors })
    case fileUploadActions.ADD_JOB_ID:
      return ReduxService.updateObject(state, { jobIds: [{ jobId: action.jobId, displayName: action.displayName, subtitle: action.subtitle, isDisabled: action.isDisabled || false }].concat(state.jobIds) })
    case fileUploadActions.ADD_FIELD:
    return ReduxService.updateObject(state, { fields: state.fields.add(emptyField) })
    case fileUploadActions.REMOVE_FIELD:
      return ReduxService.updateObject(state, { fields: state.fields.remove(action.index) })
    case fileUploadActions.CHANGE_FIELD:
      return ReduxService.updateObject(state, { fields: state.fields.updateInList({ [action.name]: action.value }, action.index) })
    case basicUploadActions.CLEAR:
      return uploadInitialState;
    default:
      return state;
  }
}