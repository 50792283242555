import { ActionService, DataLakeApi, S3Api } from 'dashboard-services';

import { basicUploadActions } from 'actions/upload';
import getApiConfig from 'api/ApiConfig';

export default (() =>  {

  const ADD_JOB_ID = 'ADD_JOB_ID_UPLOAD'
  const addJobId = ActionService.makeActionCreator(ADD_JOB_ID, 'jobId', 'displayName', 'subtitle', 'isDisabled')

  const ADD_FIELD = 'ADD_FIELD_UPLOAD'
  const addField = ActionService.makeActionCreator(ADD_FIELD)

  const REMOVE_FIELD = 'REMOVE_FIELD_UPLOAD'
  const removeField = ActionService.makeActionCreator(REMOVE_FIELD, 'index')

  const CHANGE_FIELD = 'CHANGE_FIELD_UPLOAD'
  const changeField = ActionService.makeActionCreator(CHANGE_FIELD, 'value', 'name', 'index')

  const uploadFile = ({ fileToUpload, groupName, fileName, fileType, fields }) => dispatch => {
    const metadataCall = 
      new DataLakeApi(dispatch(getApiConfig()))
        .uploadFileMetadata(groupName, fileName, fileType, fields)
        .withValidationHandler(response => basicUploadActions.setErrors(response.validationErrors || []))
        .noParsing(true)
        .build()
        .call()
        .then(response => {
          const jobId = response.headers.get("X-KH-JOB-ID")
          dispatch(addJobId(jobId, fileName, groupName, false))
          return response
        })
        .then(response => response.json())

    const fileCall = response => 
      new S3Api(dispatch(getApiConfig()))
        .upload(response.location, fileToUpload)
        .withSuccessMessage("File has been saved.")
        .build()
        .call()

    return metadataCall.then(fileCall)
  }

  return {
    uploadFile,
    addJobId,
    ADD_JOB_ID,
    addField,
    removeField,
    changeField,
    CHANGE_FIELD,
    REMOVE_FIELD,
    ADD_FIELD
  }
})()