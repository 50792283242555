import { combineReducers } from 'redux';

import { globalInitialState, globalState, loginActions, primaryComponentsInitialState, primaryComponentsState } from 'primary-components';

import datalakeState, { datalakeInitialState } from './datalake';
import dataprepState, { dataprepInitialState } from './dataprep';
import settingsState, { settingsInitialState } from './settings';
import timeseriesState, { timeseriesInitialState } from './timeseries';
import uploadState, { uploadInitialState } from './upload';

export const initialState = {
  datalakeState: datalakeInitialState,
  dataprepState: dataprepInitialState,
  timeseriesState: timeseriesInitialState,
  globalState: globalInitialState,
  primaryComponentsState: primaryComponentsInitialState,
  settingsState: settingsInitialState,
  uploadState: uploadInitialState
}

export const officeApp = (state, action) => {
  if (action.type === loginActions.LOG_OUT) {
    state = { globalState: state.globalState, primaryComponentsState: { apiConfigState: state.primaryComponentsState.apiConfigState } }
  }
  return combineReducers({
    datalakeState,
    dataprepState,
    settingsState,
    timeseriesState,
    uploadState,
    globalState,
    primaryComponentsState
  })(state, action)
}
