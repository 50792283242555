import { DataLakeApi } from 'dashboard-services';

import getApiConfig from 'api/ApiConfig';
import Formulas from 'resources/constants/Formulas';

import Papa from 'papaparse';

export default(() => {
  const FILE_PREFIX = "File: "
  const GROUP_PREFIX = " in group: "
  

  const searchFilesCore = ({ query, size, from = 0, noFetching = true, requestIdPrefix } = {}) => dispatch => {
    const config = dispatch(getApiConfig())
    return new DataLakeApi(config)
      .searchFiles({ query: query || "*", from, size })
      .noFetching(noFetching)
      .noErrorMessage(noFetching)
      .withRequestIdPrefix(requestIdPrefix)
      .build()
      .call()
  }

  const readFile = ({ fileId }) => dispatch => {
    if(!fileId) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) =>
      new DataLakeApi(dispatch(getApiConfig()))
      .getFile(fileId)
      .build()
      .call()
      .then(response => response.text())
      .then(content => 
        Papa.parse(content, {
          complete: result => {
            const newResult = result.data
            if(newResult[newResult.length - 1]?.length !== newResult[0]?.length) {
              newResult.pop()
            }
            return resolve(newResult)
          },
          error: err => {
            reject(err)
            console.error(String(err))
          }
        })
      ).catch(err => {
        console.error(String(err))
        reject(err)
      })
    )
  }

const validateTable = ({ item, cellValue, cell, formula }) => {
  if(String(cellValue).startsWith(FILE_PREFIX) && (formula?.includes(Formulas.LOAD_FILE) || formula?.includes(Formulas.LOAD_FILE.replaceAll(".", "_")))) {
    return item.concat({
      fileName: String(cellValue).slice(FILE_PREFIX.length, String(cellValue).indexOf(GROUP_PREFIX)),
      groupName: String(cellValue).slice(String(cellValue).indexOf(GROUP_PREFIX) + GROUP_PREFIX.length),
      address: cell.address
    })
  }
  return item
}


  return {
    FILE_PREFIX,
    searchFilesCore,
    GROUP_PREFIX,
    readFile,
    validateTable
  }
})()