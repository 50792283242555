import { prototypes } from 'dashboard-services';

import { AuthSingleton } from './AuthSingleton';
import ExcelUtils from './ExcelUtils';

if("".capitaliseFirstLetter === undefined) {
  prototypes.init()
}

export default (() => {

  const disableLogInButton = async () => {
    await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogIn", enabled: false })
    await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogOut", enabled: true })
  }
  const enableLogInButton = async () => {
    await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogIn", enabled: true })
    await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogOut", enabled: false })
  }

  const hasAccessToExcel = ({ uiEntitlements }) => {
    if(uiEntitlements?.system) { // if the user is a system, full access
      return true;
    }
    if(!uiEntitlements?.components) { // if the entitlements does not exist - no access
      return false;
    }
    return uiEntitlements.components.findIndex(item => item.name === "Excel" && (item.access === "*" || String(item.access).includes("R"))) > -1
  }

  const validateToken = () => (dispatch, getState) => {
    const token = getState().primaryComponentsState?.loginState?.token
    return AuthSingleton.getInstance().refreshNGToken
      .then(() => {
        if(token != null && typeof token != "undefined" && token !== "") {
          return Promise.resolve().then(() => token)
        } else {
          throw new Error("Please login before using NG custom functions")
        }
      })
  }

  return {
    disableLogInButton,
    enableLogInButton,
    hasAccessToExcel,
    validateToken
  }
})()
