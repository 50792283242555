import { ReduxService, TimeService, tsDataRanges, tsLastTypesObject } from 'dashboard-services';

import { basicTimeseriesActions } from 'actions/timeseries';

export const timeseriesInitialState = {
  activeTab: undefined,
  
  address: undefined,
  query: undefined,
  
  groupName: undefined,
  key: undefined,
  value: undefined,
  symbol: {},
  columns: undefined,

  timezone: TimeService.UTC,
  dateRange: tsDataRanges[2]?.value,
  lastValue: "500",
  lastType: tsLastTypesObject.value,
  from: undefined,
  fromTime: undefined,
  to: undefined,
  toTime: undefined,
  fromSelected: undefined,
  toSelected: undefined
}

export default (state = timeseriesInitialState, action) => {
  switch (action.type) {
    case basicTimeseriesActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case basicTimeseriesActions.UPDATE_SYMBOL:
      return ReduxService.updateObject(state, { symbol: action.fun(state.symbol) })
    case basicTimeseriesActions.CLEAR:
      return timeseriesInitialState;
    default:
      return state;
  }
}