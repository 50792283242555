import properties from 'resources/constants/properties.json';

import { AuthSingleton } from './AuthSingleton';

import ReactGA from 'react-ga4';

export default (() => {

  const sha256 = message => {
    const msgBuffer = new TextEncoder().encode(message);                    
    return crypto.subtle.digest('SHA-256', msgBuffer).then(hashBuffer => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
    })
  }

  const sendEvent = ({ category, action, label, ...props }) => {
    if(properties.gaId && properties.gaId !== "disabled") {
      const event = {
        category,
        action: "Excel_" + action,
        label,
        ...props
      };
      if(AuthSingleton.isGaInited) {
        ReactGA.event(event)
      } else {
        setTimeout(() => {
          ReactGA.event(event)
        }, 2000)
      }
    }
  }

  const sendPageView = name => {
    if(properties.gaId && properties.gaId !== "disabled") {
      ReactGA.send({ hitType: "pageview", page: `Excel${name}` });
    }
  }

  const init = ({ userName, company }) => {
    if(properties.gaId && properties.gaId !== "disabled") {
      sha256(userName).then(userId => {
        ReactGA.initialize([{
          trackingId: properties.gaId, 
          gaOptions: {
            titleCase: false,
            userId,
            "user_id": userId,
            userName,
            "org_id": company,
            company,
            // "debug_mode": true, // Needs chrome plugin
            cookieFlags: "samesite=none;secure"
          },
        }])
        ReactGA.gtag('set', 'user_properties', {
          userName,
          company,
          "org_id": company,
          "user_id": userId,
        });
        console.info("GA is up and running.")
        console.info("GA is set up.", userName, company)
        if(AuthSingleton.isGaInited === undefined) {
          AuthSingleton.isGaInited = true
        }
      })
    }
    return Promise.resolve();
  }

  return {
    init,
    sendEvent,
    sendPageView
  }
})()