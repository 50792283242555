import { ActionService } from 'dashboard-services';

export default (() => {
  
  const ON_CHANGE = 'ON_CHANGE_DATA_LAKE'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  return {
    onChange,
    ON_CHANGE
  }
})()